
import 'rxjs/add/operator/toPromise';
import { BehaviorSubject, Observable }	from 'rxjs';

import * as firebase 					from 'firebase/app';

import { Injectable, NgZone } 		from '@angular/core';
import { auth }							from 'firebase/app';
import { AngularFireAuth }				from "@angular/fire/auth";
import { Router }							from "@angular/router";
import { HttpClient }					from '@angular/common/http';
import { AngularFirestore } 			from '@angular/fire/firestore';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { Customer }						from '../_models';
import { Employee }						from '../_models';
import { Location }						from '../_models';
import { Meter }							from '../_models';
import { User }							from "../_models";
import { Utility }						from '../_models';
//import { DataService }					from '.';

@Injectable()
export class AuthService
	{
	userData: 	any; 			// Save logged in user data
	empData:		Employee;
//	URI =  'https://stoshb.info:5488/';
	URI =  'https://stoshb.info/do_sql/';

	constructor
		(
		public afs:			AngularFirestore,		// Inject Firestore service
		public afAuth: 	AngularFireAuth,		// Inject Firebase auth service
		public router: 	Router,
 		private http: 		HttpClient,
// 		private dataSrv: 	DataService ,
		public ngZone: 	NgZone					// NgZone service to remove outside scope warning
		) {
		/* Saving user data in localstorage when
		logged in and setting up null when logged out */
		this.afAuth.authState.subscribe(user => {
			if (user) {
			  this.userData = user;
			  localStorage.setItem('user', JSON.stringify(this.userData));
			  JSON.parse(localStorage.getItem('user'));
			} else {
			  localStorage.setItem('user', null);
			  JSON.parse(localStorage.getItem('user'));
//			  console.log("No user logged in")
			}
			})
		}

	doRegister(value)
		{
		return new Promise<any>((resolve, reject) => {
			firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
			.then(res => {
				resolve(res);
			}, err => reject(err));
			});
		}

	doLogin(value)
		{
		return new Promise<any>((resolve, reject) => {
			firebase.auth().signInWithEmailAndPassword(value.email, value.password)
			.then(res => {
				console.log( 'Signed in using email and password' + value.email + "/" + value.password );
				resolve(res);
			},
			err => {
				alert('Invalid Login.  Please try again.');
//				console.log('Invalid password/email');
			reject(err); });
			});
		}

	SendVerificationMail()
		{
		return this.afAuth.auth.currentUser.sendEmailVerification()
		.then(() => {
		  this.router.navigate(['verify-email-address']);
		})
		}

	doLogout()
		{
//		console.log('really trying to logout');
		return new Promise((resolve, reject) => {
//			console.log( firebase.auth().currentUser     );
			if (firebase.auth().currentUser) {
//				console.log('call signOut');
				this.afAuth.auth.signOut();
				resolve();
			} else {
//				console.log( 'REJECT');
				reject();
			}
			});
		}

	SignOut() {
//		console.log( "SignOut" ) ;
		return this.afAuth.auth.signOut().then(() => {
//			console.log('sign out')
			localStorage.removeItem('user');
			this.router.navigate(['login']);
		})
	}

	SignIn(email, password)
		{
		return this.afAuth.auth.signInWithEmailAndPassword(email, password)
			.then((result) =>
				{
				console.log("AuthID: " + result.user.uid );
				alert ("AuthID: " + result.user.uid );
				this.getUserByAuthID( result.user.uid ).subscribe( Employee => {
//					this.dataSrv.changeUtility( Employee.utility_gid );
					this.ngZone.run(() =>
						{
			  			this.router.navigate(['home']);
			  			});
			  		console.log( "Signed In User id = " + result.user.uid )  ;
					this.SetUserData(result.user, Employee);
					this.SetUtilityData( Employee.utility_gid )  ;
					this.router.navigate(['home']);
					});

				})
			.catch((error) =>
				{
				window.alert(error.message)
				})
		}

	AuthLogin(provider) {
		return this.afAuth.auth.signInWithPopup(provider)
		.then((result) => {
			this.ngZone.run(() => {
				this.router.navigate(['home']);
			})
		  this.SetUserData(result.user, this.empData);
		}).catch((error) => {
			window.alert(error)
		})
	}

	SetUserData( user, Employee ) {
		const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
		const userData: User = {
			uid: 					user.uid,
			email: 				user.email,
			emailAddr:			user.email,
			displayName:		user.displayName,
			photoURL: 			user.photoURL,
			emailVerified: 	user.emailVerified,
			gid:					Employee.gid,
			utilityGid: 		Employee.utility_gid,

//			userName:			'Why am i here.  This value not used',
			password:			'',
			firstName:			'',
			lastName:			'',
			role:					'',
			dateIn:				Employee.date_in,
			dateOut:				Employee.date_out,

			}
		userRef.set(userData, {merge: true})
		var d = new Date();
		Employee.time_last_auth = d.getTime();
		console.log( JSON.stringify( Employee) );
		localStorage.setItem('employee', JSON.stringify( Employee ));

		return;
	}

	SetUtilityData( utility_gid )
		{
		this.getUtility( utility_gid ).subscribe( Util =>
			{
	 		localStorage.setItem('utility', JSON.stringify(Util));
			});
		}

	// Reset Forgot password
	ForgotPassword(passwordResetEmail)
		{
//		console.log('Forgot Password')
		return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
			window.alert('Password reset email sent, check your inbox.');
		}).catch((error) => {
			window.alert(error)
		})
		}

	get isLoggedIn(): boolean
		{

		const user = JSON.parse(localStorage.getItem('user'));
//
//		For now allow users to access code even if email not verified
//
//		return (user !== null && user.emailVerified !== false) ? true : false;
		return (user !== null) ? true : false;
		}

	isRoleOK( testRole ): boolean
		{
		return true;
		const Emp = JSON.parse(localStorage.getItem('employee'));
//		console.log ("is Role " + testRole + " available for this user while we are in utility " + Emp.utility_gid );
//		console.log( Emp.role_array );
//		[{"t":"mh","g":-1,"v":1},{"t":"ut","g":-1,"v":1},{"t":"mc","g":-1,"v":1},
//		{"t":"mp","g":-1,"v":1},{"t":"user","g":-1,"v":1},{"t":"admin","g":-1,"v":1}]

		var retVal = false;
		var index = 0;
		while (index < Emp.role_array.length)
			{
			if ( testRole == Emp.role_array[index].t )
				{
				if ( ( Emp.role_array[index].g  == -1 )  || ( Emp.role_array[index].g  == Emp.utility_gid ))
					{
					retVal = Emp.role_array[index].v;
					}
				else
					{
//					console.log( "Role " + testRole + " not for this utility " + Emp.utility_gid);
					}
				break;
				}
			index++;
			}
		return retVal;
		}

	get userAuthUid(): string
		{
		const user = JSON.parse(localStorage.getItem('user'));
		return (user !== null) ? user.uid : null;
		}

	get currentUtility(): number
		{
		const Emp = JSON.parse(localStorage.getItem('employee'));

//		const d = new Date();
//		const DeltaT = d.getTime() - Emp.time_last_auth;
//		if (DeltaT > 1000000 )  {
//			alert("Time since login: " + DeltaT);
//			Emp.time_last_auth = d.getTime();
//			localStorage.setItem('employee', JSON.stringify(Employee));
//			}

		return (Emp.utility_gid);
		}

	get currentEmployee(): Employee
		{
		const Emp = JSON.parse(localStorage.getItem('employee'));
		return ( Emp );
		}

	get currentUserName(): string
		{
		const Emp = JSON.parse(localStorage.getItem('employee'));
		console.log( Emp );
		return ( Emp.First_Name + " " + Emp.Last_Name );
		}

	get currentUserLoggedOnAt(): number
		{
		const Emp = JSON.parse(localStorage.getItem('employee'));
		return ( Emp.time_last_auth );
		}

	get utilityLogo(  ): string
		{
		const Util = JSON.parse(localStorage.getItem('utility'));
//		console.log("Provide utility logo: http://stoshb.info/assets/" +  Util.logo_link );
//		return ( "https://titanic-182119.firebaseapp.com/assets/" +  Util.logo_link );
		return ( "https://stoshb.info/assets/" +  Util.logo_link );
//		return ( "https://nebuladev-edb6b.web.app/assets/" + '176_Nebula_logo_V2.1.png' );
		}

	get utilityObject(): Utility
		{
		const Util = JSON.parse(localStorage.getItem('utility'));
		return ( Util );
		}

	get Emp_Utility_Array(  )
		{
		const Emp = JSON.parse(localStorage.getItem('employee'));
		return ( Emp.utility_array );
		}

	get Emp_First_Name(  ): string
		{
		const Emp = JSON.parse(localStorage.getItem('employee'));
		return ( Emp.First_Name );
		}

	getUserByAuthID( auth_id ): Observable<Employee>
		{
//alert("GUBA: " + auth_id );
		const U = this.URI + 'get/user/' + auth_id;
//alert("U: " + U);
		console.log( "getUserByAuthID: " + U );
		return this.http.get<Employee>( U );
		}

	getUtility( gid?: number ): Observable<Utility>
		{
		const U  = this.URI + 'get/utility/' + gid;
//		console.log( U );
		return this.http.get<Utility>( U );
		}



}
